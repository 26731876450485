<template>
	<ZyroModal
		height="100%"
		max-width="758px"
		max-height="406px"
		position="bottom"
		class="publish-modal"
		@close-modal="closeModal"
	>
		<div class="publish-modal__wrapper">
			<h4 class="publish-modal__title z-h4">
				{{ $t('builder.curatedTemplates.readyToPublish.title') }}
			</h4>
			<i18n
				class="publish-modal__text z-body-small"
				path="builder.curatedTemplates.readyToPublish.text"
				tag="p"
			>
				<span>{{ $t('builder.curatedTemplates.readyToPublish.slots.publish') }}</span>
			</i18n>
			<ZyroButton
				class="publish-modal__button publish-modal__button--publish"
				data-qa="builder-publishmodal-btn-publishtemplate"
				theme="primary"
				color="plump-purple"
				size="xs"
				:disable="isSiteBeingPublished"
				@click.once="publishTemplate"
			>
				{{ $t('builder.header.publishTemplate') }}
			</ZyroButton>
			<ZyroButton
				class="publish-modal__button"
				data-qa="builder-publishmodal-btn-backtobuilder"
				size="xs"
				@click="closeModal"
			>
				{{ $t('common.backToBuilder') }}
			</ZyroButton>
		</div>
	</ZyroModal>
</template>

<script>
import {
	mapMutations,
	mapActions,
	mapState,
} from 'vuex';

import { publishDesignerTemplate } from '@/api/PublishApi';
import { PUBLISH_ERROR_MODAL } from '@/store/builder/constants/modals';
import {
	mapActionsGui,
	OPEN_MODAL,
	CLOSE_MODAL,
} from '@/store/builder/gui';
import {
	NOTIFICATION_TYPE_MODAL,
	NOTIFICATIONS_NAMESPACE,
	NOTIFY,
} from '@/store/builder/notifications';

export default {
	data() {
		return { isBeingUpdated: false };
	},
	computed: {
		...mapState(['domain']),
		...mapState('gui', ['isSiteBeingPublished']),
	},
	methods: {
		...mapMutations('gui', ['toggleSiteBeingPublished']),
		...mapMutations([
			'setDomain',
			'setWebsitePublishDate',
		]),
		...mapActions('gui', ['setEditMode']),
		...mapActions([
			'saveWebsite',
			'updateWebsite',
			'publishTemplate',
		]),
		...mapActionsGui({
			openModal: OPEN_MODAL,
			closeModal: CLOSE_MODAL,
		}),
		async publishTemplate() {
			this.setEditMode(false);
			try {
				await this.saveWebsite();
				if (this.domain) {
					await this.updateWebsite({ showModal: false });
				} else {
					const domain = await publishDesignerTemplate();

					this.setDomain(domain);
				}

				this.setWebsitePublishDate(new Date());
				this.closeModal();
				this.openModal({ name: 'DesignerAfterPublishModal' });
			} catch {
				this.setDomain('');

				this.$store.dispatch(`${NOTIFICATIONS_NAMESPACE}/${NOTIFY}`, {
					type: NOTIFICATION_TYPE_MODAL,
					origin: 'DesignerBeforePublishModal.vue, publishTemplate',
					props: { modalName: PUBLISH_ERROR_MODAL },
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>

.publish-modal {
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 64px 66px 61px;
	}

	&__title {
		max-width: 578px;
		margin-bottom: 24px;
		text-align: center;

		@include mobile-view {
			margin-bottom: 26px;
		}
	}

	&__text {
		max-width: 578px;
		margin-bottom: 47px;
		text-align: center;

		span {
			font-weight: 700;
		}

		@include mobile-view {
			margin-bottom: 55px;
		}
	}

	&__button {
		margin: 0;
		overflow: hidden;

		&--publish {
			margin-bottom: 26px;
		}
	}
}
</style>
